import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default-page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "680px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3c1cbfb7858931f43018c73aceea5eaa/da099/ferenc-almasi-ayjnmG4oUX4-unsplash.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "68.23529411764706%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIBBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHiTcWYD//EABUQAQEAAAAAAAAAAAAAAAAAABBB/9oACAEBAAEFAmH/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAXEAEBAQEAAAAAAAAAAAAAAAABEAAR/9oACAEBAAE/IWg4nJ//2gAMAwEAAgADAAAAEBcf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8QZ//EABwQAQADAAIDAAAAAAAAAAAAAAEAESEQYTGBkf/aAAgBAQABPxDR5PsQ7mdxQpNa2Mqa9cf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "programming",
            "title": "programming",
            "src": "/static/3c1cbfb7858931f43018c73aceea5eaa/7bf67/ferenc-almasi-ayjnmG4oUX4-unsplash.jpg",
            "srcSet": ["/static/3c1cbfb7858931f43018c73aceea5eaa/651be/ferenc-almasi-ayjnmG4oUX4-unsplash.jpg 170w", "/static/3c1cbfb7858931f43018c73aceea5eaa/d30a3/ferenc-almasi-ayjnmG4oUX4-unsplash.jpg 340w", "/static/3c1cbfb7858931f43018c73aceea5eaa/7bf67/ferenc-almasi-ayjnmG4oUX4-unsplash.jpg 680w", "/static/3c1cbfb7858931f43018c73aceea5eaa/990cb/ferenc-almasi-ayjnmG4oUX4-unsplash.jpg 1020w", "/static/3c1cbfb7858931f43018c73aceea5eaa/c44b8/ferenc-almasi-ayjnmG4oUX4-unsplash.jpg 1360w", "/static/3c1cbfb7858931f43018c73aceea5eaa/da099/ferenc-almasi-ayjnmG4oUX4-unsplash.jpg 5081w"],
            "sizes": "(max-width: 680px) 100vw, 680px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`What is state?`}</h2>
    <p>{`State is an object that is managed within your component.`}</p>
    <h2>{`When to useState?`}</h2>
    <p>{`Use state if you want a component to respond to changes.`}</p>
    <h2>{`When not to useState?`}</h2>
    <p>{`Don't use state if your component should not respond to changes.`}</p>
    <h2>{`The useState hook`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const [ state, setState ] = useState()
`}</code></pre>
    <h3>{`Let's break it down`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`useState`}</inlineCode>{` hook is a function that returns an array with exactly two items. The first item in the array is the current state, while the second item is a function to update the current state.`}</p>
    <p>{`The React hooks syntax relies heavily on the destructuring pattern. Another way to write this would be:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const stateHook = useState()
const state = stateHook[0]
const setState = stateHook[1]
`}</code></pre>
    <h2>{`Complete example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React, { useState } from 'react'

function () {
    
    const [ count, setCount ] = useState(0)

    return (
        <div>
            <p>The current count is: { count }</p>
            <button onClick={() setCount(count + 1)}>+</button>
        </div>
    )
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      